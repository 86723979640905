import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { PageLayout } from 'dibs-elements/exports/PageLayout';

import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import HpSharedLocationsModuleItem from './HpSharedLocationsModuleItem';
import HpSharedLocationsModuleItemList from './HpSharedLocationsModuleItemList';
import {
    trackShopByLocationsImpressions,
    trackShopByLocationClick,
} from '../helpers/shopByTracking';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';
import { InViewport } from 'dibs-in-viewport/exports/InViewport';
import { HpSharedHeader } from '../HpSharedHeader/HpSharedHeader';

import styles from './HpSharedLocationsModule.scss';

export const handleInitialDisplay = ({ inViewport, locations }) => {
    if (locations && inViewport) {
        locations.map((location, index) => {
            const { linkData } = location;
            trackShopByLocationsImpressions(index, linkData?.path, false);
        });
    }
};

export const HpSharedLocationsModuleComponent = ({
    componentModule,
    isMobile,
    moduleIndex,
    totalModules,
}) => {
    const { items, title, subtitle, cmsDisplayTitle } = componentModule;
    const locations = items || [];

    if (!locations.length) {
        return null;
    }

    return (
        <HpSharedModuleContainer addTopGap={!title}>
            <PageLayout setOffset={!isMobile} setViewportBackground={'sassyColorWhite'}>
                <div className={styles.content}>
                    <HpSharedHeader title={title} subtitle={subtitle} />
                    <InViewport
                        stopWhenInViewport
                        onInViewportChange={({ inViewport }) =>
                            handleInitialDisplay({
                                locations,
                                inViewport,
                            })
                        }
                    >
                        <div className={styles.locations}>
                            {locations.map((item, index) => {
                                return (
                                    <HpSharedLocationsModuleItem
                                        key={item?.cmsId || index}
                                        item={item}
                                        onClick={() => {
                                            trackShopByLocationClick(item, index);
                                            trackModuleLocation({
                                                label: 'four block module',
                                                moduleIndex,
                                                totalModules,
                                            });
                                            trackModule(cmsDisplayTitle);
                                        }}
                                    />
                                );
                            })}
                            <HpSharedLocationsModuleItemList
                                componentModule={componentModule}
                                onListItemClick={() => {
                                    trackModuleLocation({
                                        label: 'four block module',
                                        moduleIndex,
                                        totalModules,
                                    });
                                    trackModule(cmsDisplayTitle);
                                }}
                            />
                        </div>
                    </InViewport>
                </div>
            </PageLayout>
        </HpSharedModuleContainer>
    );
};

HpSharedLocationsModuleComponent.propTypes = {
    componentModule: PropTypes.object,
    viewer: PropTypes.object,
    itemsPerPage: PropTypes.number,
    isMobile: PropTypes.bool,
    useLazyLoadImages: PropTypes.bool,
    moduleIndex: PropTypes.number,
    totalModules: PropTypes.number,
};

export const HpSharedLocationsModule = createFragmentContainer(HpSharedLocationsModuleComponent, {
    componentModule: graphql`
        fragment HpSharedLocationsModule_componentModule on LocationsModule {
            title
            subtitle
            cmsDisplayTitle
            items {
                cmsId
                linkData {
                    # Used by HpSharedLocationsModuleItem
                    path
                }
                ...HpSharedLocationsModuleItem_item
            }
            ...HpSharedLocationsModuleItemList_componentModule
        }
    `,
});
