import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import { Link } from 'dibs-elements/exports/Link';
import HeartWrapper from 'dibs-portfolio-heart';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
import { addToEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';

import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';
import ResponsiveImage from '../ResponsiveImage';
import { SharedFavoritesProps } from './HpSharedHeroModule';

import styles from './LargeItemCarouselItem.scss';

import { LargeItemCarouselItem_item$key } from './__generated__/LargeItemCarouselItem_item.graphql';

const { EVENT_WISHLIST } = eventNameConstants;
const { INTERACTION_TYPE_ITEM_FAVORITING } = interactionTypeConstants;
const { STEP_INTERACTION_ITEM_ADDED, STEP_INTERACTION_ITEM_REMOVED } = stepInteractionConstants;

type Props = {
    item: LargeItemCarouselItem_item$key | null | undefined;
    isVisible?: boolean;
    onClick: () => void;
    useLazyLoadImages: boolean;
    userId: string;
    favorites: SharedFavoritesProps['favorites'];
};

const LargeItemCarouselItem: FC<Props> = ({
    item: itemRef,
    isVisible,
    onClick,
    useLazyLoadImages,
    userId,
    favorites,
}) => {
    const item = useFragment(
        graphql`
            fragment LargeItemCarouselItem_item on Item {
                serviceId
                localizedPdpUrl
                firstPhotoWebPath(size: small)
                title
            }
        `,
        itemRef
    );

    const { serviceId: itemId, localizedPdpUrl, firstPhotoWebPath, title } = item || {};

    const authModalShow = (favoriteItem: SharedFavoritesProps['favorites']): void => {
        if (!userId) {
            authModalLoader
                .show({
                    flow: authModalLoader.constants.SAVE_ITEM_FLOW,
                    ga: {
                        label: 'save item - "editorial picks" homepage module',
                    },
                })
                .then(favoriteItem);
        }
    };

    const heroItemClassName = classnames(styles.heroItem, {
        [styles.isVisible]: isVisible,
    });
    return (
        <Link
            dataTn="hero-item-link"
            href={localizedPdpUrl || ''}
            className={heroItemClassName}
            onClick={onClick}
        >
            <HeartWrapper
                itemId={itemId}
                loadPortfolioData={favorites.loadPortfolioData}
                viewer={favorites.viewer || null}
                userId={favorites.userId || null}
                userIds={favorites.userIds || []}
                authModalShow={authModalShow}
                onFavorited={() => {
                    const trigger = 'homepage - editors picks';
                    trackEvent(
                        {
                            action: STEP_INTERACTION_ITEM_ADDED,
                            label: trigger,
                            category: INTERACTION_TYPE_ITEM_FAVORITING,
                            isInteractiveEvent: true,
                            additional: { itemId },
                            eventName: EVENT_WISHLIST,
                            interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
                            step_interaction_name: STEP_INTERACTION_ITEM_ADDED,
                            trigger,
                        },
                        null
                    );
                    addToEngagedItems(itemId);
                }}
                onUnFavorited={() => {
                    const trigger = 'homepage - editors picks';
                    trackEvent(
                        {
                            action: STEP_INTERACTION_ITEM_REMOVED,
                            label: trigger,
                            category: INTERACTION_TYPE_ITEM_FAVORITING,
                            isInteractiveEvent: true,
                            additional: { itemId },
                            eventName: EVENT_WISHLIST,
                            interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
                            step_interaction_name: STEP_INTERACTION_ITEM_REMOVED,
                            trigger,
                        },
                        null
                    );
                }}
                selectedItemIds={favorites.selectedItemIds}
                theme="dark"
            />

            <HpLazyImageWrapper useLazyLoad={useLazyLoadImages} className={styles.imageWrapper}>
                <ResponsiveImage
                    // no need for alt if title is present bc labeling the img with title is
                    // redundant with title as link text. only use alt if != title.
                    ariaHidden
                    className={styles.image}
                    src={firstPhotoWebPath || ''}
                    dataTn="hero-item-image"
                    srcSetWidths={[200, 240]}
                />
            </HpLazyImageWrapper>
            <div
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: title || '' }}
                data-tn="hero-item-title"
            />
        </Link>
    );
};

export default LargeItemCarouselItem;
