import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Carousel } from 'dibs-carousel';
import { Swiper } from 'dibs-pinch-zoom-swipe';
import HpSharedCollectionsModuleItem from './HpSharedCollectionsModuleItem';
import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import { RenderIn, device } from '../../utils/RenderIn';
import { HpSharedCarouselTracking } from '../HpSharedCarouselTracking/HpSharedCarouselTracking';
import {
    trackCollectionsImpression,
    trackCollectionsItemClick,
    trackArrowClick,
} from './helpers/CollectionsTracking';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

import styles from './HpSharedCollectionsModule.scss';

import { HpSharedCollectionsModule_componentModule$data } from './__generated__/HpSharedCollectionsModule_componentModule.graphql';

type CollectionItems = NonNullable<HpSharedCollectionsModule_componentModule$data['items']>;

type Props = {
    componentModule: HpSharedCollectionsModule_componentModule$data;
    useLazyLoadImages: boolean;
    moduleIndex: number;
    totalModules: number;
};

const HpSharedCollectionsModule: FC<Props> = ({
    componentModule,
    useLazyLoadImages,
    moduleIndex,
    totalModules,
}) => {
    if (!componentModule) {
        return null;
    }

    const { title, cmsDisplayTitle } = componentModule;
    const items = (componentModule.items || []).filter(filterNulls);

    return (
        <HpSharedModuleContainer
            className={styles.wrapper}
            dataTn="collections-container"
            addTopGap={!title}
        >
            <h2 className={styles.subHeader}>{title}</h2>
            <RenderIn deviceTypes={[device.DESKTOP, device.TABLET]}>
                {() => {
                    const itemsPerPage = 5;

                    return (
                        <HpSharedCarouselTracking
                            items={items}
                            itemsPerPage={itemsPerPage}
                            onItemsImpression={({
                                visibleItems,
                                index,
                            }: {
                                visibleItems: CollectionItems;
                                index: number;
                            }) =>
                                trackCollectionsImpression({
                                    items: visibleItems,
                                    startIndex: index,
                                })
                            }
                        >
                            {({ handleIndexChange }) => (
                                <Carousel
                                    slideCarouselItems
                                    totalItems={items.length}
                                    itemsPerPage={itemsPerPage}
                                    step={itemsPerPage}
                                    renderItem={({ index }: { index: number }) => (
                                        <HpSharedCollectionsModuleItem
                                            item={items[index]}
                                            useLazyLoadImages={useLazyLoadImages}
                                            onClick={() => {
                                                trackCollectionsItemClick({
                                                    item: items[index],
                                                    index,
                                                });
                                                trackModuleLocation({
                                                    label: 'small item carousel module',
                                                    moduleIndex,
                                                    totalModules,
                                                });
                                                trackModule(cmsDisplayTitle);
                                            }}
                                        />
                                    )}
                                    onIndexChange={handleIndexChange}
                                    onLeftArrowClick={() => trackArrowClick({ type: 'previous' })}
                                    onRightArrowClick={() => trackArrowClick({ type: 'next' })}
                                />
                            )}
                        </HpSharedCarouselTracking>
                    );
                }}
            </RenderIn>
            <RenderIn deviceTypes={[device.MOBILE]}>
                {() => {
                    const itemsPerPage = 2.5;
                    return (
                        <HpSharedCarouselTracking
                            items={items}
                            itemsPerPage={itemsPerPage}
                            onItemsImpression={({
                                visibleItems,
                                index,
                            }: {
                                visibleItems: CollectionItems;
                                index: number;
                            }) =>
                                trackCollectionsImpression({
                                    items: visibleItems,
                                    startIndex: index,
                                })
                            }
                        >
                            {({
                                handlePageChange,
                            }: {
                                handlePageChange: (page: number) => void;
                            }) => (
                                <Swiper
                                    classNames={{ list: styles.swiperList }}
                                    itemsPerPage={itemsPerPage}
                                    onPageChange={(currentPage: number, previousPage: number) => {
                                        trackArrowClick({
                                            type: previousPage > currentPage ? 'previous' : 'next',
                                        });
                                        handlePageChange(currentPage);
                                    }}
                                >
                                    {items.map((item, index) => (
                                        <HpSharedCollectionsModuleItem
                                            key={item.cmsId}
                                            item={item}
                                            useLazyLoadImages={useLazyLoadImages}
                                            onClick={() => {
                                                trackCollectionsItemClick({ item, index });
                                                trackModuleLocation({
                                                    label: 'small item carousel module',
                                                    moduleIndex,
                                                    totalModules,
                                                });
                                            }}
                                        />
                                    ))}
                                </Swiper>
                            )}
                        </HpSharedCarouselTracking>
                    );
                }}
            </RenderIn>
        </HpSharedModuleContainer>
    );
};

export default createFragmentContainer(HpSharedCollectionsModule, {
    componentModule: graphql`
        fragment HpSharedCollectionsModule_componentModule on CollectionsModule {
            title
            cmsDisplayTitle
            items {
                cmsId
                title # used in tracking
                ...HpSharedCollectionsModuleItem_item
            }
        }
    `,
});
