import { FC } from 'react';
import { Environment } from 'react-relay';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { Carousel } from 'dibs-carousel';
import { Swiper } from 'dibs-pinch-zoom-swipe';
import { InViewport } from 'dibs-in-viewport/exports/InViewport';
import { ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';

import { RenderIn, device } from '../../utils/RenderIn';
import { SharedCarouselDot } from '../../sharedComponents/SharedCarouselDot/SharedCarouselDot';
import SharedFavoritesProvider from '../../sharedComponents/SharedFavoritesProvider';
import { HpSharedHeader } from '../HpSharedHeader/HpSharedHeader';
import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import { HpSharedCarouselTracking } from '../HpSharedCarouselTracking/HpSharedCarouselTracking';
import {
    trackPromoClick,
    trackPromoImpressions,
    buildPromoTrackingObject,
} from '../helpers/promoTrackingHelpers';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';
import { TABLET_ITEMS_PER_PAGE } from '../constants';
import LargeItemCarouselItem from './LargeItemCarouselItem';
import { trackProductImpression, trackProductClick } from './helpers/HeroTracking';

import { HpSharedHeroModule_viewer$data } from './__generated__/HpSharedHeroModule_viewer.graphql';
import { HpSharedHeroModule_componentModule$data } from './__generated__/HpSharedHeroModule_componentModule.graphql';

import styles from './HpSharedHeroModule.scss';

type Item = NonNullable<
    NonNullable<
        NonNullable<
            ArrayElement<NonNullable<HpSharedHeroModule_viewer$data['itemSearch']>['edges']>
        >['node']
    >['item']
>;

type Props = {
    userId: string;
    environment: Environment;
    viewer: HpSharedHeroModule_viewer$data;
    componentModule: HpSharedHeroModule_componentModule$data;
    useLazyLoadImages: boolean;
    moduleIndex: number;
    totalModules: number;
    fetchFavorites: boolean;
};

export type SharedFavoritesProps = {
    favorites: {
        loadPortfolioData: boolean;
        viewer: HpSharedHeroModule_viewer$data;
        userId: string;
        userIds: string[];
        selectedItemIds: string[];
    };
};

const HpSharedHeroModule: FC<Props> = ({
    userId,
    environment,
    viewer,
    componentModule,
    useLazyLoadImages,
    moduleIndex,
    totalModules,
    fetchFavorites,
}) => {
    if (!componentModule) {
        return null;
    }

    const { title, cmsDisplayTitle, viewMoreLink } = componentModule;
    const items = (viewer?.itemSearch?.edges || []).map(edge => edge?.node?.item);
    const totalItems = items.length;

    if (!totalItems) {
        return null;
    }

    const onItemsImpression = ({
        visibleItems,
        index,
    }: {
        visibleItems: ReadonlyArray<Item>;
        index: number;
    }): void => {
        trackProductImpression({
            items: visibleItems,
            startIndex: index,
        });
        const promoTrackingObjects = visibleItems.map(item => {
            return buildPromoTrackingObject({
                id: 'large-item-carousel',
                name: 'Large Item Carousel',
                item: {
                    linkData: {
                        path: item?.localizedPdpUrl,
                    },
                },
                index,
            });
        });

        trackPromoImpressions(promoTrackingObjects);
    };

    const handleClick = (index: number): void => {
        const item = items[index];
        trackProductClick({
            item,
            index,
        });
        const promoTrackingObject = buildPromoTrackingObject({
            id: 'large-item-carousel',
            name: 'Large Item Carousel',
            item: {
                linkData: {
                    path: item?.localizedPdpUrl,
                },
            },
            index,
        });

        trackPromoClick(promoTrackingObject);

        trackModuleLocation({
            label: 'large item carousel module',
            moduleIndex,
            totalModules,
        });
        trackModule(cmsDisplayTitle);
    };

    const favoritesProviderProps = {
        userId,
        environment,
        viewer,
        itemIds: items.map(item => item?.serviceId),
        fetchFavorites,
    };

    return (
        <SharedFavoritesProvider {...favoritesProviderProps}>
            {({ favorites }: SharedFavoritesProps) => (
                <HpSharedModuleContainer addTopGap={!title}>
                    <HpSharedHeader
                        title={title}
                        viewMoreLink={viewMoreLink || '/collections/editors-picks/'}
                        dataTn="editors-picks"
                    />
                    <RenderIn deviceTypes={[device.DESKTOP, device.TABLET]}>
                        {({ currentDevice }: { currentDevice: string }) => {
                            const itemsPerPage =
                                currentDevice === device.DESKTOP ? TABLET_ITEMS_PER_PAGE : 3;

                            return (
                                <HpSharedCarouselTracking
                                    items={items as ReadonlyArray<Item>}
                                    itemsPerPage={itemsPerPage}
                                    onItemsImpression={onItemsImpression}
                                >
                                    {({ handleIndexChange }) => (
                                        <InViewport>
                                            {({ inViewport }: { inViewport: boolean }) => (
                                                <Carousel
                                                    classNames={{
                                                        listWrapper: styles.listWrapper,
                                                    }}
                                                    isAutoRun={
                                                        inViewport && totalItems > itemsPerPage
                                                    }
                                                    dataTn="hero-items-carousel"
                                                    showDots={totalItems > itemsPerPage}
                                                    hideArrows
                                                    totalItems={totalItems}
                                                    itemsPerPage={itemsPerPage}
                                                    step={itemsPerPage}
                                                    renderItem={({
                                                        index,
                                                        isVisible,
                                                    }: {
                                                        index: number;
                                                        isVisible: boolean;
                                                    }) => (
                                                        <LargeItemCarouselItem
                                                            item={items[index]}
                                                            isVisible={isVisible}
                                                            useLazyLoadImages={useLazyLoadImages}
                                                            onClick={() => handleClick(index)}
                                                            userId={userId}
                                                            favorites={favorites}
                                                        />
                                                    )}
                                                    onIndexChange={handleIndexChange}
                                                    renderDot={({
                                                        isCurrentDot,
                                                    }: {
                                                        isCurrentDot?: boolean;
                                                    } = {}) => (
                                                        <SharedCarouselDot
                                                            isActive={isCurrentDot}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </InViewport>
                                    )}
                                </HpSharedCarouselTracking>
                            );
                        }}
                    </RenderIn>

                    <RenderIn deviceTypes={[device.MOBILE]}>
                        {() => {
                            const itemsPerPage = 1.5;

                            return (
                                <HpSharedCarouselTracking
                                    items={items as ReadonlyArray<Item>}
                                    itemsPerPage={itemsPerPage}
                                    onItemsImpression={onItemsImpression}
                                >
                                    {({ handlePageChange }) => (
                                        <Swiper
                                            itemsPerPage={itemsPerPage}
                                            onPageChange={handlePageChange}
                                            classNames={{ item: styles.swiperItem }}
                                        >
                                            {items.map((item, index) => (
                                                <LargeItemCarouselItem
                                                    key={item?.serviceId || index}
                                                    item={item}
                                                    useLazyLoadImages={useLazyLoadImages}
                                                    onClick={() => handleClick(index)}
                                                    isVisible
                                                    userId={userId}
                                                    favorites={favorites}
                                                />
                                            ))}
                                        </Swiper>
                                    )}
                                </HpSharedCarouselTracking>
                            );
                        }}
                    </RenderIn>
                </HpSharedModuleContainer>
            )}
        </SharedFavoritesProvider>
    );
};

export default createFragmentContainer(HpSharedHeroModule, {
    viewer: graphql`
        fragment HpSharedHeroModule_viewer on Viewer {
            itemSearch(uriRef: "/collections/editors-picks/?sold=false", first: 8)
                @include(if: $fetchInitialHomepage) {
                edges {
                    node {
                        item {
                            serviceId
                            localizedPdpUrl
                            title
                            contemporaryTrackingString
                            ...LargeItemCarouselItem_item
                        }
                    }
                }
            }
            ...SharedFavoritesProvider_viewer
        }
    `,
    componentModule: graphql`
        fragment HpSharedHeroModule_componentModule on HeroModule {
            title
            cmsDisplayTitle
            viewMoreLink
        }
    `,
});
