/**
 * @generated SignedSource<<bca30fb21fc3f592b942e368385a5e84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedHeroModule_viewer$data = {
  readonly itemSearch?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly item: {
          readonly contemporaryTrackingString: string;
          readonly localizedPdpUrl: string | null;
          readonly serviceId: string | null;
          readonly title: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"LargeItemCarouselItem_item">;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SharedFavoritesProvider_viewer">;
  readonly " $fragmentType": "HpSharedHeroModule_viewer";
};
export type HpSharedHeroModule_viewer$key = {
  readonly " $data"?: HpSharedHeroModule_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModule_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fetchInitialHomepage"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedHeroModule_viewer",
  "selections": [
    {
      "condition": "fetchInitialHomepage",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 8
            },
            {
              "kind": "Literal",
              "name": "uriRef",
              "value": "/collections/editors-picks/?sold=false"
            }
          ],
          "concreteType": "ItemSearchQueryConnection",
          "kind": "LinkedField",
          "name": "itemSearch",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ItemSearchQueryEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ItemSearchResult",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Item",
                      "kind": "LinkedField",
                      "name": "item",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "serviceId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "localizedPdpUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "contemporaryTrackingString",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "LargeItemCarouselItem_item"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "itemSearch(first:8,uriRef:\"/collections/editors-picks/?sold=false\")"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFavoritesProvider_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "3ab4ea85c75a69d832f4f104398e84d5";

export default node;
