/**
 * @generated SignedSource<<e10838fbdb64f4c5c6888224ad2a5523>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedHeroModule_componentModule$data = {
  readonly cmsDisplayTitle: string | null;
  readonly title: string | null;
  readonly viewMoreLink: string | null;
  readonly " $fragmentType": "HpSharedHeroModule_componentModule";
};
export type HpSharedHeroModule_componentModule$key = {
  readonly " $data"?: HpSharedHeroModule_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModule_componentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedHeroModule_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cmsDisplayTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewMoreLink",
      "storageKey": null
    }
  ],
  "type": "HeroModule",
  "abstractKey": null
};

(node as any).hash = "358f5649c488ee8eb1a82bc8deec0ada";

export default node;
