/**
 * @generated SignedSource<<07eb4986497ea33afeed5d61fc5c3342>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedLocationsModuleItemList_componentModule$data = {
  readonly location: {
    readonly backgroundColor: string | null;
    readonly description: string | null;
    readonly imageHeight: string | null;
    readonly imageUrl: string | null;
    readonly imageWidth: string | null;
    readonly items: ReadonlyArray<{
      readonly cmsId: number | null;
      readonly linkData: {
        readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
      };
      readonly title: string | null;
    } | null> | null;
    readonly title: string | null;
  } | null;
  readonly " $fragmentType": "HpSharedLocationsModuleItemList_componentModule";
};
export type HpSharedLocationsModuleItemList_componentModule$key = {
  readonly " $data"?: HpSharedLocationsModuleItemList_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedLocationsModuleItemList_componentModule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedLocationsModuleItemList_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageHeight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageWidth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backgroundColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LocationItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cmsId",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "LinkData",
              "kind": "LinkedField",
              "name": "linkData",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SeoLink_linkData"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LocationsModule",
  "abstractKey": null
};
})();

(node as any).hash = "1c2ef81a8ee0fe71b6f5877009100d25";

export default node;
