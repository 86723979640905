/**
 * @generated SignedSource<<5ccdc6204fd80a733a5aab62dce61f4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LargeItemCarouselItem_item$data = {
  readonly firstPhotoWebPath: string | null;
  readonly localizedPdpUrl: string | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "LargeItemCarouselItem_item";
};
export type LargeItemCarouselItem_item$key = {
  readonly " $data"?: LargeItemCarouselItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"LargeItemCarouselItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LargeItemCarouselItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedPdpUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "size",
          "value": "small"
        }
      ],
      "kind": "ScalarField",
      "name": "firstPhotoWebPath",
      "storageKey": "firstPhotoWebPath(size:\"small\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "a636e013d34a358bc9a175de5b572bb3";

export default node;
