import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import { GridCol } from 'dibs-elements/exports/Grid';
import slugify from 'dibs-slugify/exports/slugify';
import { trackShopByLocationClick } from '../helpers/shopByTracking';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import HpSharedModuleItemTile from '../HpSharedModuleItemTile/HpSharedModuleItemTile';

import styles from './HpSharedLocationsModuleItemList.scss';

import { HpSharedLocationsModuleItemList_componentModule$data } from './__generated__/HpSharedLocationsModuleItemList_componentModule.graphql';

type Props = {
    componentModule: HpSharedLocationsModuleItemList_componentModule$data;
    useLazyLoadImages: boolean;
    onListItemClick: () => void;
};

export const HpSharedLocationsModuleItemList: FC<Props> = ({
    componentModule,
    useLazyLoadImages,
    onListItemClick,
}) => {
    const { title, description, imageUrl, imageHeight, imageWidth, items, backgroundColor } =
        componentModule?.location || {};
    return (
        <GridCol tabletLandscape={3}>
            <div className={styles.container}>
                <HpSharedModuleItemTile
                    linkData={null}
                    dataTn="dealer-locations-tile"
                    title={title}
                    description={description}
                    imageUrl={imageUrl}
                    imgSrcSetWidths={[220, 360, 500, 760]}
                    imgSizes="(max-width:768px) 95vw, (max-width:1024px) 220px, 360px"
                    imageHeight={imageHeight}
                    imageWidth={imageWidth}
                    imageClass={styles.tileImage}
                    imageOverlayClass={styles.tileImageOverlay}
                    textClass={styles.tileText}
                    useLazyLoadImages={useLazyLoadImages}
                    backgroundColor={backgroundColor || ''}
                    imgOverlayContent={
                        <ul className={styles.locationsList}>
                            {(items || []).filter(filterNulls).map((item, index) => (
                                <li key={item.cmsId || index}>
                                    <SeoLink
                                        dataTn={`dealer-location-link-${slugify(item.title)}`}
                                        linkData={item.linkData}
                                        className={styles.locationLink}
                                        onClick={() => {
                                            trackShopByLocationClick(item, index);
                                            onListItemClick();
                                        }}
                                    >
                                        {item.title}
                                    </SeoLink>
                                </li>
                            ))}
                        </ul>
                    }
                />
            </div>
        </GridCol>
    );
};

export default createFragmentContainer(HpSharedLocationsModuleItemList, {
    componentModule: graphql`
        fragment HpSharedLocationsModuleItemList_componentModule on LocationsModule {
            location {
                title
                description
                imageUrl
                imageHeight
                imageWidth
                backgroundColor
                items {
                    cmsId
                    title
                    linkData {
                        ...SeoLink_linkData
                    }
                }
            }
        }
    `,
});
