import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { GridCol } from 'dibs-elements/exports/Grid';
import HpSharedModuleItemTile from '../HpSharedModuleItemTile/HpSharedModuleItemTile';

import styles from './HpSharedLocationsModuleItem.scss';

import { HpSharedLocationsModuleItem_item$data } from './__generated__/HpSharedLocationsModuleItem_item.graphql';

type Props = {
    item: HpSharedLocationsModuleItem_item$data;
    onClick: () => void;
    useLazyLoadImages: boolean;
};

const HpSharedLocationsModuleItem: FC<Props> = ({ item, onClick, useLazyLoadImages }) => {
    const { title, description, cta, linkData, imageUrl, imageHeight, imageWidth } = item;
    return (
        <GridCol tabletLandscape={3}>
            <div className={styles.item}>
                <HpSharedModuleItemTile
                    linkData={linkData}
                    dataTn={`locations-module-item-${title}`}
                    onClick={onClick}
                    title={title}
                    description={description}
                    cta={cta}
                    imageUrl={imageUrl}
                    imgSrcSetWidths={[220, 360, 500, 760]}
                    imgSizes="(max-width:768px) 95vw, (max-width:1024px) 220px, 360px"
                    textClass={styles.tileText}
                    imageClass={styles.tileImage}
                    imageOverlayClass={styles.tileImageOverlay}
                    imageHeight={imageHeight}
                    imageWidth={imageWidth}
                    useLazyLoadImages={useLazyLoadImages}
                />
            </div>
        </GridCol>
    );
};

export default createFragmentContainer(HpSharedLocationsModuleItem, {
    item: graphql`
        fragment HpSharedLocationsModuleItem_item on Product {
            title
            description
            cta
            imageUrl
            imageHeight
            imageWidth
            linkData {
                ...HpSharedModuleItemTile_linkData
            }
        }
    `,
});
