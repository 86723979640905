import { FC } from 'react';
import { Link } from 'dibs-elements/exports/Link';
import { FormattedMessage } from 'dibs-react-intl';
import { Maybe } from 'dibs-ts-utils/exports/Maybe';
import { trackEvent } from 'dibs-tracking';

import styles from './HpSharedHeader.scss';

type Props = {
    title: Maybe<string>;
    subtitle?: string;
    viewMoreLink?: string;
    dataTn?: string;
};

export const HpSharedHeader: FC<Props> = ({ title, subtitle, viewMoreLink, dataTn }) =>
    title ? (
        <div className={styles.headerContainer}>
            <div className={styles.titleWrapper}>
                <div className={styles.title} data-tn={dataTn ? `${dataTn}-title` : null}>
                    {title}
                </div>
                {viewMoreLink && (
                    <Link
                        className={styles.viewMore}
                        href={viewMoreLink}
                        dataTn="home-page-view-more-link"
                        //only "Editor’s Picks" has "viewMoreLink" property
                        onClick={() => {
                            trackEvent({
                                category: 'promo interaction',
                                action: 'editors picks view more clicked',
                                label: 'homepage',
                                noninteraction: 0,
                            });
                        }}
                    >
                        <FormattedMessage id="HpSharedHeader.viewMore" defaultMessage="View More" />
                    </Link>
                )}
            </div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
    ) : null;
