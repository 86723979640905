import { FC, useState, useEffect, useCallback } from 'react';
import { createRefetchContainer, graphql, RelayRefetchProp } from 'react-relay/legacy';
import { getBuyerId, getUserType } from 'dibs-cookie-jar';
import { TRADE } from 'dibs-buyer-layout/exports/userTypeEnums';
import { addLoginHandlers } from 'dibs-buyer-layout/src/utils/loginHandlers';
import { trackPageview, trackUserEventHomepageView } from 'dibs-tracking';
import { mobile } from 'dibs-client-check';
import { useIntl } from 'dibs-react-intl';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';
import { useHomePageContext } from './HomePageContext';
import { getItemsPerPage } from './helpers/getItemsPerPage';
import getLazyLoadStartingPoint from './helpers/getLazyLoadStartingPoint';
import { trackHPVideoAutoPlayTest } from './helpers/HPVideoAutoPlayAbTestHelpers';
import { HpSharedAppBannerLazy } from './HpSharedAppBanner/HpSharedAppBannerLazy';
import { HpSharedCompleteEmailOptinConfirmationModalLazy } from './HpSharedCompleteEmailOptinConfirmationModal/HpSharedCompleteEmailOptinConfirmationModalLazy';
import { trackSellerBrandingRemovalAbTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';
import {
    getUserSessionCountryCode,
    getUserZipCode,
} from 'dibs-regional-info/exports/regionalInfoHelpers';
import { getLocalRecentHistoryList } from 'dibs-recent-history/exports/getLocalRecentHistoryList';
import { componentModuleMap } from './componentModuleMap';
import { useOffersOnHpVariant, useTrackOffersOnHp } from './helpers/abTestOffersOnHp';

import { dibsLazyClient } from 'dibs-lazy/exports/dibsLazyClient';
import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';
const HpRespPrivateOffersToast = dibsLazyClient(
    () =>
        import(
            /* webpackChunkName: "HpRespPrivateOffersToast"  */ './HpRespPrivateOffersToast/HpRespPrivateOffersToast'
        )
);
const HpMobilePrivateOffers = dibsLazyClient(
    () =>
        import(
            /* webpackChunkName: "HpMobilePrivateOffers"  */ './HpMobilePrivateOffers/HpMobilePrivateOffers'
        )
);

import { HpSharedLayout_viewer$data } from './__generated__/HpSharedLayout_viewer.graphql';

type HpSharedLayoutProps = {
    relay: RelayRefetchProp;
    homepageUserGroup: string;
    viewer: HpSharedLayout_viewer$data;
};

const HpSharedLayoutComponent: FC<HpSharedLayoutProps> = ({ relay, viewer, homepageUserGroup }) => {
    const intl = useIntl();
    const [userId, setUserId] = useState<string | null>(null);
    const [userType, setUserType] = useState<string | null>(null);
    const [isClient, setIsClient] = useState<boolean | null>(null);
    const [fetchFavorites, setFetchFavorites] = useState(false);

    const { isMobile, isTablet, isLoggedInUser } = useHomePageContext();
    const { currency } = useCurrency();

    const itemsPerPage = getItemsPerPage({ isMobile, isTablet });

    const user = viewer?.user || null;

    const buyerActivePrivateOffers = viewer?.buyerActivePrivateOffers || null;
    const offersOnHpVariant = useOffersOnHpVariant();
    const isOffersOnHpVariant = offersOnHpVariant === 'variant';
    const isOffersOnHpControl = offersOnHpVariant === 'control';
    const fetchBuyerActiveOffers = !!userId && (isOffersOnHpVariant || isOffersOnHpControl);
    useTrackOffersOnHp(buyerActivePrivateOffers);

    const doRefetch = useCallback(
        (additionalVariables = {}) => {
            const localRecentHistoryList = getLocalRecentHistoryList().map(item => ({
                itemId: item.itemId,
                timestamp: item.timestamp,
            }));

            const recentActivityDesignVariant = isMobile ? 'mobile' : 'default';
            const _userType = homepageUserGroup || userType || '';

            relay.refetch(
                fragmentVariables => ({
                    ...fragmentVariables,
                    fetchInitialHomepage: false,
                    isClient: true,
                    isMobile,
                    hasUserId: !!userId,
                    userId: userId || '',
                    userIds: userId ? [userId] : [],
                    userType: _userType,
                    zipCode: getUserZipCode(),
                    localRecentHistoryList,
                    recentActivityDesignVariant,
                    isTrade: _userType === TRADE,
                    userCountryCode: getUserSessionCountryCode(),
                    fetchBuyerActiveOffers, //TODO: replace fetchBuyerActiveOffers with a hasUserId check if test is successful
                    activePrivateOfferCount: isMobile ? 2 : 3,
                    ...additionalVariables,
                }),
                { fetchInitialHomepage: true },
                () => {
                    setIsClient(true);

                    // ensure that we are done with refetch before considering to allow favorites fetch
                    if (userId) {
                        setFetchFavorites(true);
                    }
                }
            );
        },
        [userId, userType, relay, isMobile, homepageUserGroup, fetchBuyerActiveOffers]
    );

    const setUserData = useCallback(() => {
        const cookieUserId = getBuyerId(document.cookie);

        if (cookieUserId && !userId) {
            // order is important to prevent unnecessary refetch, read more: https://github.com/1stdibs/ferrum/pull/13601#pullrequestreview-818832289
            setUserType(getUserType(document.cookie));
            setUserId(cookieUserId);
        }
    }, [userId]);

    useEffect(() => {
        trackHPVideoAutoPlayTest();
    }, []);

    useEffect(() => {
        // Remove 'seller-directory-redirect=true' check, when 'SellerBrandRemoval' abTest is over
        const params = new URLSearchParams(window.location.search);
        const sellerDirectoryRedirect = params.get('seller-directory-redirect');
        const opt_pageURL =
            sellerDirectoryRedirect === 'true' ? '/?seller-directory-redirect=true' : '/';
        trackPageview({ additional: { opt_pageURL } });

        trackSellerBrandingRemovalAbTestVariant();
    }, []);

    useEffect(() => {
        trackUserEventHomepageView(relay.environment);
    }, [relay.environment]);

    useEffect(() => {
        if (!isLoggedInUser && !isClient) {
            doRefetch();
        }
    }, [doRefetch, isLoggedInUser, isClient]);

    useEffect(() => {
        if (userId && !user) {
            doRefetch();
        }
    }, [doRefetch, userId, user]);

    useEffect(() => {
        setUserData();
        addLoginHandlers((data: { user: { isVerifiedTrade?: boolean | null } }) => {
            if (data?.user?.isVerifiedTrade) {
                // keep this to make sure a trade member
                // logging in on the hp is "redirected"
                // to the trade homepage
                window.location.reload();
                return;
            }
            setUserData();
        });
    }, [setUserData, intl.locale]);

    const { homepage } = viewer;

    const isUser = isLoggedInUser || !!userId; // isLoggedInUser comes at load; userId might come later from refetch

    const { personalization, modules } = homepage || {};
    const promoPrimaryModule = (modules || []).find(
        mod => mod && mod.type === 'promo' && mod.index === 0
    );
    const promoPrimaryItemsCount = (promoPrimaryModule?.messages?.items || []).length;
    const startLazyLoadAt = getLazyLoadStartingPoint({ isMobile, isTablet });
    const hasLoggedInWithApp = user?.hasLoggedInWithApp;

    const mods = (modules || []).map((mod, index) => {
        if (!mod) {
            return null;
        }
        let type = mod.type;

        if (type === 'promo') {
            type = mod.index === 0 ? 'promoPrimary' : 'promoSecondary';
        }

        if (!type) {
            return null;
        }

        const component = componentModuleMap[type];
        if (component) {
            return component({
                componentModule: mod,
                key: `${type}-${index}`,
                promoPrimaryItemsCount,
                viewer,
                isMobile,
                isTablet,
                itemsPerPage,
                isUser,
                personalization,
                useLazyLoadImages: index >= startLazyLoadAt,
                moduleIndex: index,
                totalModules: modules?.length || 0,
                userId: userId || '',
                environment: relay.environment,
                fetchFavorites,
            });
        }
        return null;
    });

    if (isClient && isMobile && buyerActivePrivateOffers?.totalResults && isOffersOnHpVariant) {
        /**
         * Hacky way to insert the private offers toast in the middle of the modules.
         * If test is successful, we will move this to homepage CMS.
         */
        mods.splice(
            1,
            0,
            <ClientSuspense key="privateOffers" fallback={null}>
                <HpMobilePrivateOffers
                    buyerActivePrivateOffers={buyerActivePrivateOffers}
                    currency={currency}
                />
            </ClientSuspense>
        );
    }

    return (
        <>
            {mods}
            {isClient && <HpSharedCompleteEmailOptinConfirmationModalLazy />}
            {!!(
                isClient &&
                !isMobile &&
                buyerActivePrivateOffers?.totalResults &&
                isOffersOnHpVariant
            ) && (
                <ClientSuspense fallback={null}>
                    <HpRespPrivateOffersToast
                        buyerActivePrivateOffers={buyerActivePrivateOffers}
                        currency={currency}
                    />
                </ClientSuspense>
            )}
            {isClient &&
                (mobile.iPhone() || mobile.iPad()) &&
                !(isLoggedInUser && hasLoggedInWithApp) && <HpSharedAppBannerLazy />}
        </>
    );
};

export const HpSharedLayout = createRefetchContainer(
    HpSharedLayoutComponent,
    {
        viewer: graphql`
            fragment HpSharedLayout_viewer on Viewer {
                homepage(rootId: $homepageId, abTestList: $abTestList) {
                    modules {
                        ... on SkinnyBannerModule {
                            type
                        }
                        # don't skip this module on refetch, otherwise the dibs-carousel within
                        # won't get any items
                        ... on TopHeroBannerModule {
                            type
                            ...HpSharedTopHeroBannerModule_componentModule
                        }
                        ... on HeroModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedHeroModule_componentModule
                        }
                        ... on CollectionsModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedCollectionsModule_componentModule
                        }
                        ... on FeaturedModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedFeaturedModule_componentModule
                        }
                        ... on PromoModule {
                            index
                            type
                            messages(userType: $userType) {
                                items {
                                    __typename
                                }
                            }
                            ...HpSharedPromoModulePrimary_componentModule
                            ...HpSharedPromoModuleSecondary_componentModule
                        }
                        ... on EditorialModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedEditorialModule_componentModule
                        }
                        ... on ShopByModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedShopByModule_componentModule
                        }
                        ... on LocationsModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedLocationsModule_componentModule
                        }
                        ... on ArrivalsModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedPersonalizedCategories_componentModule
                        }
                        ... on RecentlyViewedModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedRecentlyViewedItems_componentModule
                        }
                        ... on RecentActivityModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedRecentActivityModule_componentModule
                        }
                        ... on RecommendedItemsModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedRecommendedItems_componentModule
                        }
                        ... on CollectionSpotlightModule {
                            type
                            ...HpSharedCollectionSpotlightModule_componentModule
                        }
                    }
                    personalization(userId: $userId, first: 3, numItems: 3)
                        @include(if: $hasUserId) {
                        ...HpSharedPersonalizedCategories_personalization
                    }
                }
                ...HpSharedHeroModule_viewer
                ...HpSharedRecentActivityModule_viewer
                ...HpSharedRecommendedItems_viewer
                user(userId: $userId) @include(if: $hasUserId) {
                    hasLoggedInWithApp: hasLoggedInWithDeviceType(deviceType: IOS_APP)
                }
                buyerActivePrivateOffers(userId: $userId, page: 0, first: $activePrivateOfferCount)
                    @include(if: $fetchBuyerActiveOffers) {
                    ...abTestOffersOnHp_buyerActivePrivateOffers
                    ...HpRespPrivateOffersToast_buyerActivePrivateOffers
                    ...HpMobilePrivateOffers_buyerActivePrivateOffers
                    totalResults
                }
            }
        `,
    },
    graphql`
        query HpSharedLayoutRefetchQuery(
            $hasUserId: Boolean!
            $userId: String!
            $homepageId: String!
            $isMobile: Boolean!
            $fetchInitialHomepage: Boolean!
            $localRecentHistoryList: [LocalRecentHistoryListInputType] = []
            $recentActivityDesignVariant: String = "default"
            $userType: String!
            $abTestList: [String] = []
            $userCountryCode: String!
            $isTrade: Boolean!
            $priceBookName: String!
            $fetchBuyerActiveOffers: Boolean!
            $activePrivateOfferCount: Int!
        ) {
            viewer {
                ...HpSharedLayout_viewer
            }
        }
    `
);
